import { useRouteQuery } from '@vueuse/router'
import { computed, toValue, MaybeRefOrGetter } from 'vue'
import { useCompaniesQuery } from '@/api/useCompany'
import { SelectOption } from '../components/baseSelect'
import { useCurrentCompany } from './useCurrentCompany'

export const emptyOptionKey = -1
export const companyQueryKey = 'company'

export const useCompanySelect = (
  emptyOptionTitle?: MaybeRefOrGetter<string>
) => {
  const { currentCompanyId } = useCurrentCompany()
  const selectedCompanyId = useRouteQuery(
    companyQueryKey,
    emptyOptionTitle ? emptyOptionKey : currentCompanyId,
    { transform: Number }
  )

  const { data: companies } = useCompaniesQuery({
    includeCurrentCompany: true,
    includeDescendantCompanies: true,
  })

  const options = computed(() => {
    const options: SelectOption<number | null>[] = (companies.value ?? []).map(
      (company) => ({
        key: company.id,
        title: company.name ?? '',
      })
    )

    if (emptyOptionTitle) {
      options.unshift({
        key: emptyOptionKey,
        title: toValue(emptyOptionTitle),
      })
    }

    return options
  })

  const hasMoreThanOneCompany = computed(
    () => (companies.value?.length ?? 0) > 1
  )

  return { selectedCompanyId, options, hasMoreThanOneCompany }
}
